export enum ProductModule {
  Accommodation = 1,
  Transfers = 2,
  Flight_Charter = 4,
  Tour_Operator = 5,
  Car_Hire = 6,
  Courses = 41,
  Activities_Tours = 55,
  Tools = 87,
  SupplierSettings = 90,
  Affiliates = 94,
}

export enum ProductSubModuleAccommodation {
  Establishment = 9,
  Rooms = 10,
  Boards = 11,
  RatePlans = 34,
  Rates = 35,
  Policies = 36,
  // PaymentPolicies = 36,
  // CancellationPolicies = 37,
  Availability = 13,
  // AgeGroups = 14,
  PackagingAndExtras = 15,
  SpecialOffers = 16,
  Periods = 33,
  Marketing = 39,
  Reports = 67,
}

export enum AccommodationPermissions {
  ViewAllHotels = 78,
}

export enum ProductSubModuleCourses {
  AgeCategories = 42,
  Certifications = 43,
  Courses = 44,
  Levels = 45,
  Outlets = 46,
  Periods = 47,
  Pricings = 48,
  Supplements = 49,
  Themes = 50,
  Bookings = 51,
}

export enum ProductSubModuleTransfers {
  Vehicles = 17,
  Points = 18,
  Zones = 19,
  Routes = 20,
  AgeGroups = 21,
  SpecialOffers = 22,
  Agents = 23,
  ViewBookings = 24,
  Marketing = 40,
}

export enum ProductSubModuleTourOperator {
  Configuration = 25,
  Packages = 26,
  Markups = 27,
  PaymentSchedules = 28,
  CancellationProtection = 29,
  ThemesAndBoxes = 30,
  Adverts = 31,
  ViewBookings = 32,
  Bedbanks = 38,
  CalendarSettings = 52,
  PromoCodesManager = 53,
  EmailTranslator = 54,
  FlightsMaxDuration = 69,
  HotDeals = 71,
  FlightAllotment = 72,
  Suppliers = 73,
  Documents = 74,
  FlightCache = 77,
  ScheduleChanges = 83,
  ConvertSupplierInvoice = 86,
  ReseguidenLinkGenerator = 88,
  DestinationSettings = 91,
  GDSScheduleChangesConverter = 99
}

export enum TourOperatorViewBookingsRestrictions {
  ViewCostPrices = 75,
  BookingModificationFunctionalities = 76,
  ViewBookingStatuses = 97,
  ViewBookingCommentsAndLogs = 102
}

export enum TourOperatorViewBookingsPermissions {
  RequestBookingCancellation = 79,
  SetBookingCancellationProcessed = 80,
  ConfirmBookingCancellation = 81,
  WhitelistRebookErrors = 82,
  SetVoucherAmount = 84,
  ViewFinancialTransaction = 85,
  ModifyCardData = 89,
  ViewBookingDocuments = 93,
  ExportBookings = 98,
  ExportRefundClaims = 100,
  ExportProductAudit = 101,
  ExportExpectedRefunds = 103
}

export enum ProductSubModuleActivitiesTours {
  Tours = 56,
  AgeCategories = 57,
  Supplements = 59,
  Bookings = 61,
  MeetingPoint = 62,
  Themes = 60,
  Schedules = 63,
  Pricing = 58,
  Marketing = 64,
  SpecialOffers = 66,
}

export enum ProductSubModuleAffiliates {
  Affiliate = 95,
  Website = 96,
}
